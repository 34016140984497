<template>
  <div
    id="tour-card"
    title="Tour"
  >
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="tourStart"
    >
    {{$t('Start Tour')}}
    </b-button>

    <app-tour :steps="steps" :options="{ highlight: true }"/>

    
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTour from '@core/components/app-tour/AppTour.vue'


export default {
  name: 'ShepherdExample',
  components: {
    
    BButton,
    AppTour,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      
      steps: [
        {
          target: '#tour-card',
          header: {
            title: 'Card',
          },
          content: 'This card contains demo for the tour.',
        }
      ],
    }
  },
  methods: {

    // tour steps
    tourStart() {
      this.$tours.vuexyTour.start()
      console.log('Tour started');
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>
